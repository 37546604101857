import { post, deletes, get, downFile } from 'common/request'
export default {
//  分页
list:args => get('/scOrder/list', args),
// 部门人员树
deptTree:args => get('/dept/deptUserTree', args),
// 新增
add:args => post('/scGridWorker/add', args),
// 编辑
edit:args => post('/scGridWorker/update', args),
// 删除
dels:args => post(`/scGridWorker/delete?id=${args}`),
// 详情
detail:args => get(`/scOrder/getById?id=${args}`),
}
